/**
 * Checkin Component
 * Represents a clickable link/button that will get location
 */

import Vue from "vue";

import template from "templates/Checkin.html";

export default Vue.extend({
  "props": {
    "href": {
      "type": String,
      "required": true
    },
    "name": {
      "type": String,
      "required": true
    },
    "process": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "required": true
    }
  },
  "template": template,
  "data": function() {
    return {
      "checkin": null,
      "checkout": null,
      "checkoutnew": null,
      "navigator_status":"failed",
    };
  },
  "methods": {
    "doreq": function(){
      this.navigator_status = "failed";
      const httpreq = require ("httpreq");
      const options = {
        method: "POST",
        url: "https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyC4zuvqVGdPVrEA2M5B8jc8cPDbbOv9hbI",
        json: {},
        timeout: 2000
      };

      httpreq.doRequest (options, (err, res) => {
        console.warn("navigator failed--");
        let data = res && res.body || "";
        let error = null;

        try {
          data = JSON.parse (data);
        } catch (e) {
          error = new Error ("invalid response");
          error.error = e;
        }

        if (err) {
          error = new Error ("request failed");
          error.error = err;
        }

        if (data && data.error) {
          error = new Error ("api error");
          error.error = data.error.errors;
        }

        if (error) {
          error.statusCode = res && res.statusCode;
          //callback (error);
          console.log("error", error);
        } else {
          apiGeolocationSuccess({coords: 
          {latitude: data.location.lat, 
            longitude: data.location.lng}});
        }
      });

      const apiGeolocationSuccess = position => {
        console.warn("navigator failed");
        if (this.process === "checkin") {
          this.$set("checkin", position.coords);
        }else if (this.process === "checkoutnew") {
          this.$set("checkoutnew", position.coords);
        } else {
          this.$set("checkout", position.coords);
        }
      };
    },
    "getLocation": function() {
      const options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      const success = pos => {
        if (this.process === "checkin") {
          this.$set("checkin", pos.coords);
        }else if (this.process === "checkoutnew") {
          this.$set("checkoutnew", pos.coords);
        } else {
          this.$set("checkout", pos.coords);
        }

      };

      const error = err => {
        console.warn("ERROR(" + err.code + "): " + err.message);
        this.doreq();
      };

      if (navigator.geolocation) {
        this.navigator_status = "success";
        navigator.geolocation.getCurrentPosition(success, error, options);
      } else {
        console.warn("Geolocation is not supported by this browser.");
        this.doreq();
      }

      
    },
    "onSubmit":function(e){
      const youngPersonId = document.getElementById("personId").value;
      e.target.action = "/checkout?person_id=" + youngPersonId + "&dashboard=yes";
    },

    "validateShiftOnSubmitCheckout": function(){
      const error = document.getElementsByClassName("shift-errors");            
      for(let i = 0; i<error.length; i++){
        error[i].style.display = "none";
      }
      const allsec = document.getElementsByClassName("sleeps-sec");
      //time spent
      const timeSpentType = document.getElementsByClassName("timeSpentType");
      const timeSpentHours = document.getElementsByClassName("timeSpentHours");
      const timeSpentMinutes = document.getElementsByClassName("timeSpentMinutes");
      const timeSpentError = document.getElementsByClassName("timeSpentError");
      for(let j = 0; j<allsec.length; j++){
        const start = new Date(document.getElementsByClassName("min_start_sleep")[j].value);
        const end = new Date(document.getElementsByClassName("max_start_sleep")[j].value);
        if(isNaN(start.getTime()) || isNaN(end.getTime())){
          const shiftError = document.getElementsByClassName("shift-errors")[j];
          shiftError.innerHTML = "Please enter shift time";
          shiftError.style.display = "block";
          return false;
        }
        if(start.getTime() > end.getTime()){
          const shiftError = document.getElementsByClassName("shift-errors")[j];
          shiftError.innerHTML = "Please enter valid shift time";
          shiftError.style.display = "block";
          return false;
        }
        if(timeSpentType[j] && timeSpentType[j].options[timeSpentType[j].selectedIndex].value==="1"){
          if(timeSpentHours[j].value==="" && timeSpentMinutes[j].value===""){
            const timeError = timeSpentError[j];
            timeError.innerHTML = "Please enter spent time in hours OR minutes";
            timeError.style.display = "block";
            return false;
          }
        }


        const edit_sleep_type = document.getElementsByClassName("sleep-flage")[j];
        const pare_sleeps = document.getElementsByClassName("pare-sleeps")[j];
        if(edit_sleep_type && edit_sleep_type.options[edit_sleep_type.selectedIndex].value==="1"){
          const error = document.getElementsByClassName("sleep-errors");            
          for(let i = 0; i<error.length; i++){
            error[i].style.display = "none";
          }

          const sleep_intimes = pare_sleeps.querySelectorAll(".sleep-intimes");
          const sleep_outimes = pare_sleeps.querySelectorAll(".sleep-outtimes");
          const slpError = pare_sleeps.querySelectorAll(".sleep-errors");
          const slpStartError = pare_sleeps.querySelectorAll(".slpStartError");
          const slpEndError = pare_sleeps.querySelectorAll(".slpEndError");
          const allsuperWiseType = pare_sleeps.querySelectorAll(".allsuperWiseType");
          const allsuperWiseValue = pare_sleeps.querySelectorAll(".allsuperWiseValue");
          const distSlpError = pare_sleeps.querySelectorAll(".DistSlpError");

          for(let i = 0; i<sleep_intimes.length; i++){
            const sleepInTime = new Date(sleep_intimes[i].value);
            const sleepOutTime = new Date(sleep_outimes[i].value);
            for(let i = 0; i<slpError.length; i++){
              slpError[i].style.display = "none";
            }

            if(isNaN(sleepInTime.getTime())){
              const slinTimeError = slpStartError[i];
              slinTimeError.innerHTML = "Please select sleep start time";
              slinTimeError.style.display = "block";
              return false;
            }
            if(isNaN(sleepOutTime.getTime())){
              const sloutTimeError = slpEndError[i];
              sloutTimeError.innerHTML = "Please select sleep end time";
              sloutTimeError.style.display = "block";
              return false;
            }
            if (sleepInTime.getTime() > sleepOutTime.getTime()) {
              const slinoutTimeError = slpStartError[i];
              slinoutTimeError.innerHTML = "Sleep start time should be less to sleep end time";
              slinoutTimeError.style.display = "block";
              return false;
            }
            if (start.getTime() > sleepInTime.getTime() || end.getTime() < sleepOutTime.getTime()) {
              const shiftslinoutTimeError = slpStartError[i];
              shiftslinoutTimeError.innerHTML = "Sleep time should be in bitween shift time";
              shiftslinoutTimeError.style.display = "block";
              return false;
            }

            if(allsuperWiseType[i].options[allsuperWiseType[i].selectedIndex].value==="1"){
              if(allsuperWiseValue[i].value===""){
                const distSlpErrorError = distSlpError[i];
                distSlpErrorError.innerHTML = "Awake minutes should not be blank!";
                distSlpErrorError.style.display = "block";
                return false;
              }
              const min = this.getMinuts(sleepInTime,sleepOutTime);
              if(allsuperWiseValue[i].value>min){
                const distSlpErrorError = distSlpError[i];
                distSlpErrorError.innerHTML = "Awake minutes should be less then sleep time "+min;
                distSlpErrorError.style.display = "block";
                return false;
              }
            }

            if(!this.betweencheck(pare_sleeps,i)){
              return false;
            }
          }
        }
      }
      document.getElementById("checkin_and_checkout").submit();
    },

    betweencheck: function(allsec,key){
      const sleep_intimes = allsec.querySelectorAll(".sleep-intimes");
      const sleep_outimes = allsec.querySelectorAll(".sleep-outtimes");
      const slpStartError = allsec.querySelectorAll(".slpStartEndError");
      const inTime = new Date(sleep_intimes[key].value);
      const outTime = new Date(sleep_outimes[key].value);
      for(let i = 0; i<sleep_intimes.length; i++){
        if(key!==i){
          const sleepInTime = new Date(sleep_intimes[i].value);
          const sleepOutTime = new Date(sleep_outimes[i].value);
          if ((inTime.getTime() < sleepInTime.getTime() && outTime.getTime() > sleepOutTime.getTime()) || (inTime.getTime() < sleepInTime.getTime() && outTime.getTime() > sleepInTime.getTime()) || (inTime.getTime() < sleepOutTime.getTime() && outTime.getTime() > sleepOutTime.getTime())) {
            const orverlp = slpStartError[i];
            orverlp.innerHTML = "Sleep Overlaped with other sleep ("+sleep_intimes[key].value+" To "+sleep_outimes[key].value+")";
            orverlp.style.display = "block";
            return false;
          }
        }
      }
      return true;
    },
    getMinuts: function(intime,outTime){
      const difference = outTime.getTime() - intime.getTime(); 
      return Math.round(difference / 60000); 
    },
  }
});
