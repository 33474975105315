import Vue from "vue";
import alert from "sweetalert2";
export default Vue.extend({
  ready() {
    console.log("Showing list of rotas");
  },
  methods: {
    generated({ url, data }) {
      const buttons = this.$el.querySelectorAll(".rota-request-button");
      for (const btn of Array.from(buttons)) {
        if (btn.getAttribute("href") === url) {
          const link = document.createElement("a");
          link.textContent = "View";
          link.setAttribute("href", `/monitor/rota/view/${data.id}`);
          btn.parentNode.replaceChild(link, btn);
          break;
        }
      }
    },
    rotadeletefun:function(id){
      alert({
        title: "",
        text: "Are you sure want to delete?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: "Yes",
        currentProgressStep: 0}
      ).then(() => {
        this.$http.get("/monitor/rota/delete/"+id, function(response){
          if (response.success === true) {
            alert({
              title: "Success",
              type: "success",
              currentProgressStep: 0,
            }).then(() => {
              window.location.reload();
            });
          }
        });
      });
    }
  }
});
