/**
 * Chart Component
 *
 */

import Vue from "vue";
import Chart from "chart.js";
import template from "templates/Chart.html";

export default Vue.extend({
  "props": {
    "type": {
      "type": String,
      "required": true
    },
    "width": {
      "type": String
    },
    "height": {
      "type": String
    },
    "class": {
      "type": String
    },
    "label": {
      "type": String,
      "default": ""
    },
    "x": {
      "type": String,
      "required": true
    },
    "xlabel": {
      "type": String,
      "default": "Month"
    },
    "y": {
      "type": String,
    },
    "ylabel": {
      "type": String,
      "default": "Percentage"
    },
    "y1": {
      "type": String,
    },
    "y2": {
      "type": String,
    },
    "y3": {
      "type": String,
    },
    "y4": {
      "type": String,
    },
    "y5": {
      "type": String,
    },
    "y6": {
      "type": String,
    },
    "y7": {
      "type": String,
    },
    "y8": {
      "type": String,
    },
    "y9": {
      "type": String,
    },
    "y10": {
      "type": String,
    }
  },
  "template": template,
  "ready": function() {
    let data = null;
    let ctx = null;

    if (this.type === "bar") {
      data = {
        labels: this.x.split(","),
        datasets: [
          {
            label: "Section Completed Percentage",
            backgroundColor: "rgba(219, 65, 55, 1)",
            data: this.y.split(","),
          }
        ]
      };

      ctx = this.$el.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: data,
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    }

    if (this.type === "groupableBar") {
      Chart.defaults.groupableBar = Chart.helpers.clone(Chart.defaults.bar);

      Chart.controllers.groupableBar = Chart.controllers.bar.extend({
        calculateBarX: function(index) {
          // position the bars based on the stack index
          const stackIndex = this.getMeta().stackIndex;
          return Chart.controllers.bar.prototype.calculateBarX
            .apply(this, [index, stackIndex]);
        },

        hideOtherStacks: function(datasetIndex) {
          const meta = this.getMeta();
          const stackIndex = meta.stackIndex;

          this.hiddens = [];
          for (let i = 0; i < datasetIndex; i++) {
            const dsMeta = this.chart.getDatasetMeta(i);
            if (dsMeta.stackIndex !== stackIndex) {
              this.hiddens.push(dsMeta.hidden);
              dsMeta.hidden = true;
            }
          }
        },

        unhideOtherStacks: function(datasetIndex) {
          const meta = this.getMeta();
          const stackIndex = meta.stackIndex;

          for (let i = 0; i < datasetIndex; i++) {
            const dsMeta = this.chart.getDatasetMeta(i);
            if (dsMeta.stackIndex !== stackIndex) {
              dsMeta.hidden = this.hiddens.unshift();
            }
          }
        },

        calculateBarY: function(index, datasetIndex) {
          this.hideOtherStacks(datasetIndex);
          const barY = Chart.controllers.bar.prototype.calculateBarY
            .apply(this, [index, datasetIndex]);
          this.unhideOtherStacks(datasetIndex);
          return barY;
        },

        calculateBarBase: function(datasetIndex, index) {
          this.hideOtherStacks(datasetIndex);
          const barBase = Chart.controllers.bar.prototype.calculateBarBase
            .apply(this, [datasetIndex, index]);
          this.unhideOtherStacks(datasetIndex);
          return barBase;
        },

        getBarCount: function() {
          const stacks = [];

          // put the stack index in the dataset meta
          Chart.helpers.each(this.chart.data.datasets,
            function(dataset, datasetIndex) {
              const meta = this.chart.getDatasetMeta(datasetIndex);
              if (meta.bar && this.chart.isDatasetVisible(datasetIndex)) {
                let stackIndex = stacks.indexOf(dataset.stack);
                if (stackIndex === -1) {
                  stackIndex = stacks.length;
                  stacks.push(dataset.stack);
                }
                meta.stackIndex = stackIndex;
              }
            }, this);

          this.getMeta().stacks = stacks;
          return stacks.length;
        },
      });

      data = {
        labels: this.x.split(","),
        datasets: [
          {
            label: "Overhead Cost",
            backgroundColor: "rgba(54, 162, 235, 1)",
            data: this.y1.split(","),
            stack: 1
          },
          {
            label: "Additional Cost",
            backgroundColor: "rgba(153, 102, 255, 1)",
            data: this.y2.split(","),
            stack: 1
          },
          {
            label: "Placement Price",
            backgroundColor: "rgba(252, 209, 0, 1)",
            data: this.y3.split(","),
            stack: 2
          },
          {
            label: "Turnover from Placement Price",
            backgroundColor: "rgba(51, 204, 51, 1)",
            data: this.y4.split(","),
            stack: 2
          },
          {
            label: "Loss",
            backgroundColor: "rgba(252, 83, 0, 1)",
            data: this.y5.split(","),
            stack: 2
          }
        ]
      };

      ctx = this.$el.getContext("2d");
      new Chart(ctx, {
        type: "groupableBar",
        data: data,
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              stacked: true,
            }]
          }
          // Code to add total at top for every stacked bar
          /*,
          animation: {
            duration: 0,
            onComplete: function () {
              let ctx = this.chart.ctx;
              ctx.font = Chart.helpers.fontString(Chart.defaults
                .global.defaultFontFamily,
                'normal', Chart.defaults.global.defaultFontFamily);
              ctx.textAlign = 'center';
              ctx.textBaseline = 'bottom';

              this.data.datasets.forEach(function (dataset) {
                for (let i = 0; i < dataset.data.length; i++) {
                  let model = dataset._meta[Object
                    .keys(dataset._meta)[0]].data[i]._model,
                    scale_max = dataset._meta[Object.keys(dataset._meta)[0]]
                    .data[i]._yScale.maxHeight;
                  ctx.fillStyle = '#444';
                  let y_pos = model.y - 5;
                  // Make sure data value does not get overflown and hidden
                  // when the bar's value is too close to max value of scale
                  // Note: The y value is reverse, it counts from top down
                  if ((scale_max - model.y) / scale_max >= 0.93)
                    y_pos = model.y + 20;
                  if (dataset.data[i] > 0) {
                    ctx.fillText(dataset.data[i], model.x, y_pos);
                  }
                }
              });
            }
          }*/
        }
      });
    }

    if (this.type === "aimsGroupableBar") {
      Chart.defaults.groupableBar = Chart.helpers.clone(Chart.defaults.bar);

      Chart.controllers.groupableBar = Chart.controllers.bar.extend({
        calculateBarX: function(index) {
          // position the bars based on the stack index
          const stackIndex = this.getMeta().stackIndex;
          return Chart.controllers.bar.prototype.calculateBarX
            .apply(this, [index, stackIndex]);
        },

        hideOtherStacks: function(datasetIndex) {
          const meta = this.getMeta();
          const stackIndex = meta.stackIndex;

          this.hiddens = [];
          for (let i = 0; i < datasetIndex; i++) {
            const dsMeta = this.chart.getDatasetMeta(i);
            if (dsMeta.stackIndex !== stackIndex) {
              this.hiddens.push(dsMeta.hidden);
              dsMeta.hidden = true;
            }
          }
        },

        unhideOtherStacks: function(datasetIndex) {
          const meta = this.getMeta();
          const stackIndex = meta.stackIndex;

          for (let i = 0; i < datasetIndex; i++) {
            const dsMeta = this.chart.getDatasetMeta(i);
            if (dsMeta.stackIndex !== stackIndex) {
              dsMeta.hidden = this.hiddens.unshift();
            }
          }
        },

        calculateBarY: function(index, datasetIndex) {
          this.hideOtherStacks(datasetIndex);
          const barY = Chart.controllers.bar.prototype.calculateBarY
            .apply(this, [index, datasetIndex]);
          this.unhideOtherStacks(datasetIndex);
          return barY;
        },

        calculateBarBase: function(datasetIndex, index) {
          this.hideOtherStacks(datasetIndex);
          const barBase = Chart.controllers.bar.prototype.calculateBarBase
            .apply(this, [datasetIndex, index]);
          this.unhideOtherStacks(datasetIndex);
          return barBase;
        },

        getBarCount: function() {
          const stacks = [];

          // put the stack index in the dataset meta
          Chart.helpers.each(this.chart.data.datasets,
            function(dataset, datasetIndex) {
              const meta = this.chart.getDatasetMeta(datasetIndex);
              if (meta.bar && this.chart.isDatasetVisible(datasetIndex)) {
                let stackIndex = stacks.indexOf(dataset.stack);
                if (stackIndex === -1) {
                  stackIndex = stacks.length;
                  stacks.push(dataset.stack);
                }
                meta.stackIndex = stackIndex;
              }
            }, this);

          this.getMeta().stacks = stacks;
          return stacks.length;
        },
      });

      data = {
        labels: this.x.split(","),
        datasets: [
          {
            label: "Actual Scored",
            backgroundColor: "rgba(54, 162, 235, 1)",
            data: [],
            stack: 1
          },
          {
            label: "Scored Maximum",
            backgroundColor: "rgba(252, 209, 0, 1)",
            data: [],
            stack: 1
          },
          {
            label: "All Aims",
            backgroundColor: "rgba(219, 65, 55, 1)",
            data: [],
            stack: 1
          }
        ]
      };

      ctx = this.$el.getContext("2d");
      new Chart(ctx, {
        type: "groupableBar",
        data: data,
        options: {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              stacked: true,
            }]
          }
        }
      });
    }

    if (this.type === "multiLine") {
      data = {
        labels: this.x.split(","),
        datasets: [
          {
            label: "Complaint Register",
            backgroundColor: "rgba(54, 162, 235, 0.8)",
            borderColor: "rgba(54, 162, 235, 0.8)",
            data: this.y1.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Environmental Risk",
            backgroundColor: "rgba(153, 102, 255, 0.8)",
            borderColor: "rgba(153, 102, 255, 0.8)",
            data: this.y2.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Fire Register",
            backgroundColor: "rgba(252, 209, 0, 0.8)",
            borderColor: "rgba(252, 209, 0, 0.8)",
            data: this.y3.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "First Aid",
            backgroundColor: "rgba(51, 204, 51, 0.8)",
            borderColor: "rgba(51, 204, 51, 0.8)",
            data: this.y4.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Inventory",
            backgroundColor: "rgba(252, 83, 0, 0.8)",
            borderColor: "rgba(252, 83, 0, 0.8)",
            data: this.y5.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Maintenance",
            backgroundColor: "rgba(102, 51, 0, 0.8)",
            borderColor: "rgba(102, 51, 0, 0.8)",
            data: this.y6.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Prescribed Medication",
            backgroundColor: "rgba(204, 102, 255, 0.8)",
            borderColor: "rgba(204, 102, 255, 0.8)",
            data: this.y7.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Missing From Home Register",
            backgroundColor: "rgba(153, 51, 0, 0.8)",
            borderColor: "rgba(153, 51, 0, 0.8)",
            data: this.y8.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Room Search Record",
            backgroundColor: "rgba(153, 102, 0, 0.8)",
            borderColor: "rgba(153, 102, 0, 0.8)",
            data: this.y9.split(","),
            lineTension: 0,
            fill: false,
          },
          {
            label: "Weekly Vehicle Checklist",
            backgroundColor: "rgba(255, 51, 204, 0.8)",
            borderColor: "rgba(255, 51, 204, 0.8)",
            data: this.y10.split(","),
            lineTension: 0,
            fill: false,
          }
        ]
      };

      ctx = this.$el.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          responsive: true,
          legend: {
            position: "right",
          },
          tooltips: {
            mode: "label",
            callbacks: {

            }
          },
          hover: {
            mode: "dataset"
          },
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true,
                stepSize: 1
              }
            }]
          }
        }
      });
    }
    if (this.type === "line") {
      data = {
        labels: this.x.split(","),
        datasets: [
          {
            label: this.label,
            backgroundColor: "rgba(54, 162, 235, 1)",
            borderColor: "rgba(54, 162, 235, 1)",
            data: this.y.split(","),
            lineTension: 0,
            fill: false,
          }
        ]
      };

      ctx = this.$el.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: data,
        options: {
          responsive: true,
          tooltips: {
            mode: "label",
            callbacks: {

            }
          },
          hover: {
            mode: "dataset"
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.xlabel
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: this.ylabel
              },
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    }
  }
});
