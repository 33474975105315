/**
 * Datepicker Component
 *
 */

import Vue from "vue";
import template from "templates/VueTextarea.html";
//import emojer from "emojer.js";
import sn from "google-caja-sanitizer";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "required": false
    },
    "placeholder": {
      "type": String,
      "required": false
    },
    "value": {
      "type": String,
      "required": false
    },
    "data": {
      "type": String,
      "required": false
    },
    "editable": {
      "type": String,
      "required": false
    }
  },
  "template": template,
  "ready": function() {

    

    const parent = this.$el;
    const textarea = parent.getElementsByTagName("TEXTAREA")[0];
    const slot_data = parent.getElementsByClassName("slot_data")[0];
    const edit_div = parent.getElementsByClassName("vuetextarea")[0];
    
    let content = slot_data.innerHTML;
    console.log("before-content",JSON.stringify(content));
    content = sn.sanitize(content);
    content = this.sanitizeContent(content);
    content = this.sanitizeContent(content);
    
    console.log("after-content",JSON.stringify(content));
    //console.log("readyconsole", content);
    edit_div.innerHTML = content;
    textarea.innerText = content;
    textarea.value = content;
    (content !== "") ?  edit_div.style.height="auto":edit_div.style.minHeight="100px";    
    edit_div.addEventListener("input", function() {
      textarea.innerText = edit_div.innerHTML;
      textarea.value = edit_div.innerHTML;
    }, false);

    if(this.editable === "false")
    {
      edit_div.setAttribute("contenteditable", false);
    }

    // window.onload = function () {
    //   form.onsubmit = function onSubmit() {
    //     //console.log("signature pad 1",_this.signaturePad.isEmpty());
    //     content = this.sanitizeContent(content);
    //   };
    // };

    
  },
  methods: {
    parseHtmlTag: function(event) {
      //const regex = /(?!<br.*>)(?!<\/br.*>)(?!<br\/>)(<([^>]+)>)/ig;
      const parent = this.$el;
      const edit_div = parent.getElementsByClassName("vuetextarea")[0];
      

      let content = event.target.innerHTML;
      console.log("before-content",JSON.stringify(content));
      content = sn.sanitize(content);
      content = this.sanitizeContent(content);
      content = this.sanitizeContent(content);
      
      //content = emojer.parse(content);
      console.log("after-content",JSON.stringify(content));
      event.target.innerHTML = content;
      edit_div.innerHTML = content;
      event.target.value = content;
      //console.log("vuecontent",content);

    },
    sanitizeContent: function(content){
      const replaceAll = (str, search, replacement) => {  
        const target = str;
        return target.replace(new RegExp(search, "g"), replacement);
      };

      content = replaceAll(content, "&lt;", "<");
      content = replaceAll(content, "&gt;", ">");
      content = replaceAll(content, "&gt", ">");

      // Regex to remove unwanted attributes and in line css in content
      const regex = /<(\/)?()([^>]*)>/g;
      // const divregex = /<(\/)?div([^>]*)>/g;
      // const pregex = /<(\/)?p([^>]*)>/g;
      // const aregex = /<(\/)?a([^>]*)>/g;
      // const h1regex = /<(\/)?h1([^>]*)>/g;
      // const h2regex = /<(\/)?h2([^>]*)>/g;
      // const h3regex = /<(\/)?h3([^>]*)>/g;
      // const h4regex = /<(\/)?h4([^>]*)>/g;
      // content = replaceAll(content, divregex, "<$1p>");
      // content = replaceAll(content, pregex, "<$1p>");
      // content = replaceAll(content, aregex, "<$1p>");
      // content = replaceAll(content, h1regex, "<$1p>");
      // content = replaceAll(content, h2regex, "<$1p>");
      // content = replaceAll(content, h3regex, "<$1p>");
      // content = replaceAll(content, h4regex, "<$1p>");
      content = replaceAll(content, regex, "<$1p$2>");
      content = replaceAll(content, "&nbsp;&nbsp;", "");
      content = replaceAll(content, "<p></p>", "</br>");
      content = replaceAll(content, "\\r", "");
      content = replaceAll(content, "\\n", "");
      content = replaceAll(content, "\\t", "");
      //Matches Everything except P empty tags
      content = replaceAll(content, /<[^\/>p][^>]*><\/[^>]+>/, "");
      //content = replaceAll(content, "<p></p>", "</br>");
      content = replaceAll(content, "\\\"", "");
      content = content.replace(/\s\s+/g, "  ");

      return content;
    }
  }
});
