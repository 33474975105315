/**
 * DatepickerFilter Component
 *
 */

import Vue from "vue";
import flatpickr from "flatpickr";
import template from "templates/Datepickerfilter.html";
import moment from "moment";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "required": false
    },
    "placeholder": {
      "type": String,
      "required": false
    },
    "value": {
      "type": String,
      "required": false
    },
    "format": {
      "type": String,
      "required": false,
      "default": "d/m/Y"
    },
    "maxdate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "mindate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "enabletime": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "enableseconds": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "nocalendar": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "filterDate": {
      "type": String,
      "required": false
    },
    "to": {
      "type": String,
      "required": false
    },
  },
  "template": template,
  watch: {
    to: function() {
      
      if(this.to !== "" && this.to !== undefined && this.to !== null){
        //this.mindate = this.to;
        const todDate = moment(this.to,"DD/MM/YYYY").format("YYYY-MM-DD");
        this.$el._flatpickr.set("minDate", new Date(todDate));
        //this.$el._flatpickr.config.minDate = new Date(todDate);
        this.$el._flatpickr.clear();
        //this.$el._flatpickr.set("defaultDate", new Date());
        //console.log("to", this.to, this.value ,this.$el._flatpickr);
      }
    },
    value: function() {
      if(this.value === ""){
        this.$el._flatpickr.clear();
        this.$el._flatpickr.set("defaultDate", new Date());
        //console.log("value is empty");
      }
    }
  },
  "ready": function() {
    
    const date = new Date();
    let todayDate = "";
    const parent = this.$el;
    const regex = /(\d{4})\/(\d{2})\/(\d{2})/;
    //console.log("regex",regex.test(this.value));
    //console.log("date", this.value, "calendar", this.nocalendar,parent.value);
    if(this.nocalendar === "true" && parent.value !== "")
    {
      todayDate = parent.value;
    }
    else if(this.nocalendar === "true" && parent.value === "")
    {
      //todayDate = date.getHours() +":"+ date.getMinutes();
      todayDate = null;
    }
    if((regex.test(this.value)) && (this.nocalendar === "false") )
    {
      //console.log("nil", this.value);
      todayDate = date.getDate() +"/"+ (date.getMonth()+1) +"/"+ date.getFullYear();
    }
    else if((regex.test(this.value)) && (this.nocalendar === "false"))
    {
      //console.log("not nil", this.value);
      todayDate = new Date(this.value);
    }
    this.setup(todayDate);
  },
  "methods": {
    setup: function(todayDate){
      
      flatpickr(this.$el, {
        dateFormat: this.format,
        enableTime: this.enabletime,
        enableSeconds: this.enableseconds,
        noCalendar: this.nocalendar,
        maxDate: new Date(this.maxdate),
        minDate: new Date(this.mindate),
        defaultDate :todayDate
      });
    }
  }
});
