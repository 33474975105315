import Vue from "vue";

import template from "../templates/RotaGenerator.html";
import alert from "sweetalert2";

export default Vue.extend({
  template,
  props: {
    rotasend: String,
    send: String,
    previous: Object,
  },
  data() {
    return {
      area_id:null,
      year:null,
      week:null,
      loadingGenerate: false,
      loadMore: false,
    };
  },
  methods: {
    generate(copy = false) {
      const parent = this.$els;
      parent.loader_content.parentElement.previousElementSibling.style.display="none";
      this.loadingGenerate=true;
      this.loadMore=false;
      this.$http.post(this.send, {
        copy,
      }).then((response) => {
        this.loadingGenerate=false;
        this.area_id = response.data.area_id;
        this.year = response.data.year;
        this.week = response.data.week;
        console.log(response,response.data.left);
        if(response.data.left>0){
          const buton = "<button class='list__item__actions__btn list__item__actions__btn--edit' v-on:click='generate("+false+")'>There is a lot of data, please click here to continue generating</button>";
          parent.ajax_content.innerHTML = buton;
          parent.ajax_content.parentElement.previousElementSibling.style.display="none";
          this.$compile(document);
          this.loadMore=true;
        }else{
          alert({
            title: "Success",
            type: "success",
            currentProgressStep: 0,
          }).then(() => {
            //window.location = "/monitor/rota/all/"+this.area_id+"/"+this.year+"/"+this.week;
            window.location.reload();
          });
        }
      });
    },
    confirm() {
      if (this.previous) { // Ask if they want to copy
        alert({
          "title": "Generate or Copy rota?",
          "text": `
            Would you like to generate a new rota or copy from last week?
          `,
          "type": "question",
          "confirmButtonText": "Copy",
          "cancelButtonText": "Generate New",
          "showCancelButton": true,
          "allowEscapeKey": true,
          "currentProgressStep": 0,
        }).then(() => {
          return "copy";
        }, response => {
          return response;
        })
        .then(response => {
          const rotaWeekYear =  this.rotasend.split("/");
          switch (response) {
          case "copy":
            window.location ="/monitor/rota/rota-history/" + this.previous.id + "/" +
            rotaWeekYear[0] + "/" + rotaWeekYear[1];
            return;
          case "cancel":
            return this.generate();
          default:
            return;
          }
        });
      } else {
        this.generate();
      }
    }
  }
});
