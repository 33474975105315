/**
 * Datepicker Component
 *
 */

import Vue from "vue";
import flatpickr from "flatpickr";
import template from "templates/Datepicker.html";
import moment from "moment";
import alert from "sweetalert2";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "required": false
    },
    "placeholder": {
      "type": String,
      "required": false
    },
    "value": {
      "type": String,
      "required": false
    },
    "format": {
      "type": String,
      "required": false,
      "default": "d/m/Y"
    },
    "maxdate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "mindate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "enabletime": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "enableseconds": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "nocalendar": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "instance": {
      "type": Object,
      "required": false,
    }
  },
  "template": template,
  "ready": function() {
    const $this =  this;
    let todayDate = "";
    //const parent = this.$el;
    
    //console.log("regex",regex.test(this.value));
    //console.log("date", this.value, "calendar", this.nocalendar,parent.value);

    if(this.format !== ""){
      this.format = getFlatpickrFormat(this.format);
      this.$el.setAttribute("format", this.format);
    }
    if(this.value !== ""){
      todayDate = moment(this.value).format(getMomentFormat(this.format));
      this.value = todayDate;
    }
    console.log("todayDate", todayDate, this.format, getMomentFormat(this.format));

    this.instance = flatpickr(this.$el, {
      dateFormat: this.format,
      enableTime: this.enabletime,
      enableSeconds: this.enableseconds,
      noCalendar: this.nocalendar,
      maxDate: new Date(this.maxdate),
      minDate: new Date(this.mindate),
      defaultDate :todayDate,
      allowInput: true,
      onChange: function(selectedDate) {
        //console.log("onchange");
        const mid_week_expiry_date = document.getElementsByName("mid_week_expiry_date")[0];
        if (typeof(mid_week_expiry_date) !== "undefined" && mid_week_expiry_date !== null) {
          document.getElementsByClassName("custom_package_expiry_date_wrapper")[0]
            .style.display = "block";
          const expiryDate = new Date(mid_week_expiry_date.value);
          const selectedDate_value = new Date(selectedDate);
          //console.log("===="+selectedDate_value+" > "+expiryDate);
          if(selectedDate_value > expiryDate) {

            const diff = selectedDate_value.getDate() - selectedDate_value.getDay() + (selectedDate_value.getDay() === 0 ? -6 : 1);
            const start_monday = new Date(selectedDate_value.setDate(diff));
            const d = start_monday.getDate();
            const m = start_monday.getMonth() + 1; //Month from 0 to 11
            const y = start_monday.getFullYear();
        
            document.getElementsByName("start_date")[0].value= (d <= 9 ? "0" + d : d)+"/"+(m<=9 ? "0" + m : m)+"/"+y;

            const lastDay = start_monday;
            lastDay.setDate(lastDay.getDate()+7);

            const d_last = start_monday.getDate();
            const m_last = start_monday.getMonth() + 1; //Month from 0 to 11
            const y_last = start_monday.getFullYear();
            
            document.getElementsByClassName("custom_package_expiry_date")[0].innerText = (d_last <= 9 ? "0" + d_last : d_last)+"/"+(m_last<=9 ? "0" + m_last : m_last)+"/"+y_last;
              
            document.getElementsByClassName("repeat_custom_package")[0].style.display = "block";
          } else {
            //console.log("========else=======");            
            let addfirstValue=selectedDate_value.getDay();
            if(selectedDate_value.getDay()===0){
              addfirstValue=7;
            }
            const first = (selectedDate_value.getDate() - addfirstValue + 1);
            const last = first + 7;
            //console.log("==>"+first+" , "+last);            
            const lastDay = new Date(selectedDate_value.setDate(last));
            document.getElementsByClassName("custom_package_expiry_date")[0].innerText = lastDay
              .getDate()+"/"+(lastDay.getMonth()+1)+"/"+lastDay.getFullYear();
            
            // document.getElementsByClassName("custom_package_expiry_date")[0].innerText = expiryDate
            //   .getDate()+"/"+(expiryDate.getMonth()+1)+"/"+expiryDate.getFullYear();
            
            document.getElementsByClassName("repeat_custom_package")[0].style.display = "none";
          }
        }else{
          $this.change();
        }
      }
      
    });
    function getMomentFormat(format){
      format = format.trim();
      format = format.toLowerCase();
      format = format.replace("d", "DD");
      format = format.replace("m", "MM");
      format = format.replace("y", "YYYY");
      format = format.replace("h", "HH");
      format = format.replace("i", "mm");
      format = format.replace(/s+/gm, "ss");
      //format = format.replace("ss", "s");
      //format = format.replace("s", "ss");
      //format = format.replace("Y", "YYYY");
      return format;
    }
    function getFlatpickrFormat(format){
      format = format.trim();
      //format = format.toLowerCase();
      format = format.replace("S", "s");
      format = format.replace(/s+/gm, "S");
      //format = format.replace("ss", "s");
      //format = format.replace("s", "ss");
      //format = format.replace("Y", "YYYY");
      return format;
    }
  },
  methods: {
    change: function() {
      const $this = this;
      
      const parent = this.$el;
      //const formattedDate = moment(parent.value).format(getFormat(this.format));
      console.log(this.format, parent.value, this.value, "change format", getFormat(this.format));
      const value = moment(parent.value, getFormat(this.format), true).isValid();
      if(parent.value === ""){
        this.value = "";
        return;
      }
      if(value === false){
        showAlert(parent, "Required Format is "+getFormat(this.format), $this);
      }else{
        const cdate = moment(parent.value,getFormat(this.format));
        //console.log(value,cdate.year(), cdate.hours(), parent.value.length);
        if(cdate.year() < 1900){
          //console.log("year is beyond", cdate.year());
          showAlert(parent, "Date Must be beyond 1900", $this);
        }if(cdate.year() > 3000){
          showAlert(parent, "Date Must not be beyond Year 3000", $this);
        }if(parent.value.length > 15){
          const dateData = parent.value.split(" ");
          const hourData = dateData[1].split(":");
          if(hourData[0] >= 24){
            //console.log("hours", parseInt(hourData[0]));
            showAlert(parent, "Required Format is "+getFormat(this.format), $this);
          }
          
        }
      }
      
      //console.log(parent.value, "datepicker", value, getFormat(this.format), this.instance);
      function getFormat(format){
        format = format.trim();
        format = format.toLowerCase();
        format = format.replace("d", "DD");
        format = format.replace("m", "MM");
        format = format.replace("y", "YYYY");
        format = format.replace("h", "HH");
        format = format.replace("i", "mm");
        format = format.replace("ss", "s");
        format = format.replace("s", "ss");
        //format = format.replace("Y", "YYYY");
        return format;
      }

      function showAlert(parent, message, context){
        const $this = context;
        alert({
          "title": "Invalid Format : "+parent.value,
          "text": message,
          "type": "error",
          "currentProgressStep": 0,
        }).then(() => {
          parent.value = "";
          //window.location = this.redirect_path;
        }).catch(function(exception){
          if(exception === "cancel" || exception === "esc"){
            parent.value = "";
          }else{
            parent.value = "";
            //$this.instance.clear();
            $this.instance.close();
          }
        });
      }
    },
  },
});
