import Vue from "vue";
import template from "../templates/TimeDisplay.html";
import moment from "moment";

const TOTAL_TIME = 1440;

export default Vue.extend({
  template,
  props: {
    day: Number,
    editable: Boolean,
    slots: {

      type: Array,
      default: [],
    },
    shift_start: {
      type: Number,
      default: 0
    },
    shift_end: {
      type: Number,
      default: 0
    },
    shift_start_flag: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      chunks: [],
    };
  },
  computed: {
    dayOfWeek() {
      return moment.weekdays(this.day);
    },
    inputs() {
      const out = [];
      let current = null;
      for (const [i, on] of this.chunks.entries()) {
        if (!on && current) { // Slot ended
          current.push((i - 1)*30);
          out.push(current);
          current = null;
        } else if (on && !current) { // Slot started
          current = [i*30];
        }
      }
      if (current) {
        current.push(TOTAL_TIME);
        out.push(current);
      }
      return out;
    },
    timeSlots() {
      return this.chunks.map((on, i) => {
        const from = i * 30;
        const to = from + 30;
        console.log(from,to,i);
        const start = Math.round(10000 * (from / TOTAL_TIME)) / 100;
        const end = Math.round(10000 * (to / TOTAL_TIME)) / 100;

        return {
          left: start + "%",
          width: (end - start) + "%",
          from,
          to,
          display: on ? "block" : "none",
        };
      });
    }
  },
  filters: {
    time(minutes) {
      const date = moment().startOf("day").add(minutes, "minutes");
      return date.format("HH:mm");
    }
  },
  methods: {
    toggleSlot(slot) {
      console.log(slot, this.chunks[slot]);
      if (!this.editable) {
        return;
      }
      const chunks = [...this.chunks];
      chunks[slot] = !this.chunks[slot];
      this.$set("chunks", chunks);
    },
    isKeyPressed(event){      
      if(event.shiftKey){                
        const dataV = event.target.getAttribute("data-id");
        if(this.shift_start_flag===true){
          this.shift_start = dataV;
          this.shift_start_flag = false;
          this.shift_end = 0;
        }else{
          this.shift_end = dataV;
          this.shift_start_flag = true;
        }
        //console.log("shift and slot num",+dataV);
      }else{
         
        //console.log("not shift and slot num",+dataV2);
        this.shift_start = 0;
        this.shift_end = 0;
      }
      //console.log("start=>"+this.shift_start,"end=>"+this.shift_end);
      
      if(this.shift_end > 0){        
        //console.log("---if----",+this.shift_start,"end--",+this.shift_end);
        
        for (let start = Number(this.shift_start)+1; start < this.shift_end; start++){          
          //console.log("slot=="+start);
          //this.toggleSlot(start);
          const chunks = [...this.chunks];
          if(this.chunks[start]===false){
            //this.chunks[start]=false;
            chunks[start] = !this.chunks[start];
            this.$set("chunks", chunks);  
          }
          
        }
      }
    },
    myFunction(event){
      this.shift_start=0;
      this.shift_end=48;
      
      if(event.target.checked === true){
        //console.log("check");
        if(this.shift_end > 0){ 
          for (let start = 0; start < this.shift_end; start++){          
             
            const chunks = [...this.chunks];
            if(this.chunks[start]===false){
            //this.chunks[start]=false;
              chunks[start] = !this.chunks[start];
              this.$set("chunks", chunks);  
            }
          
          }
        }
      }else{
        if(this.shift_end > 0){ 
          for (let start = 0; start < this.shift_end; start++){          
             
            const chunks = [...this.chunks];
            console.log(this.chunks[start]);
            if(this.chunks[start]===true){
            //this.chunks[start]=false;
              chunks[start] = !this.chunks[start];
              this.$set("chunks", chunks);  
            }
          
          }
        }
      }
      //console.log("start check",+this.shift_start,"end check",+this.shift_end);
    }//end
  },
  ready() {
    outer:
    for (let i = 0; i < 48; i++) {
      const minute = i * 30;
      for (const { from, to } of this.slots) {
        if (from <= minute && to > minute) {
          this.chunks.push(true);
          continue outer;
        }
      }
      this.chunks.push(false);
    }
  },
});
