/**
 * Modal Component
 * Represents a modal for form input
 */

import Vue from "vue";

import template from "templates/Modal.html";
import Newtemplate from "templates/NewModal.html";

export default Vue.extend({
  "props": {
    "show": {
      "type": Boolean,
      "required": true,
      "twoWay": true
    },
    "shownew": {
      "type": String,
      "required": false,
      "default":"no"
    }
  },
  "template": template,
  methods: {
    close() {
      const parentpath = this._props.show.parentPath;
      if(parentpath === "bodyMapImageModal"){
        document.getElementById("showbodyMapDets").click();
        document.getElementById("checkBDAddEntry").click();
      }
      else if(parentpath === "deleteBodyMap"){
        document.getElementById("showbodyMapDets").click();
        document.getElementById("checkBDDeleteEntry").click();
      }
      this.show = false;
    },
  },
  created: function () {
    document.addEventListener("keydown", (e) => {
      if (this.show && e.keyCode === 27) {
        console.log("call this value");
        return false;
        //this.close();
      }
    });
  }
});

export const SimpleModal = Vue.extend({
  "props": {
    "show": {
      "type": Boolean,
      "required": true,
    }
  },
  "template": template,
  "methods": {
    close() {
      this.$dispatch("closed");
    }
  }
});


export const NewModal = Vue.extend({
  "props": {
    "show": {
      "type": Boolean,
      "required": true,
    }
  },
  "template": Newtemplate,
  "methods": {
    close() {
      this.$dispatch("closed");
    }
  }
});
