/**
 * RequestButton Component
 * Represents a clickable link/button that will perform a request
 * via AJAX with an optional confirmation dialogue
 */

import Vue from "vue";
import alert from "sweetalert2";
import topbar from "topbar";
import { SimpleModal } from "./Modal";

import template from "templates/RequestButtonNew.html";

export default Vue.extend({
  components: {
    SimpleModal,
  },
  "props": {
    "href": {
      "type": String,
      "required": true
    },
    "staff": {
      "type": String,
      "required": false
    },
    "admissiondate": {
      "type": String,
      "required": false
    },
    "showModal": {
      "type": Boolean,
      "required": false,
      "twoWay": true
    },
    "method": {
      "type": String,
    },
    "deletes": {
      "type": Boolean
    },
    "confirm": {
      "type": String,
      "validator": function(value) {
        return !!value.length;
      }
    },
    "color": {
      "type": String,
      "validator": function(value) {
        return /#[0-9A-F]{3}([0-9A-F]{3})?/.test(value);
      }
    },
    "access_denied_msg": {
      "type": String,
      "default":"Access denied."
    }
  },
  "template": template,
  "methods": {
    "callModel" : function(){
      this.showModal = true;
    },
    "archive" : function(){
      const $this = this;
      const url1 = this.href;
      const _do = () => {  
        topbar.show();
        $this.showModal = true;
        this.$http({
          "url": this.href,
          "method": this.method ? this.method : "GET"
        })
        .then(({ data }) => {
          this.$dispatch("success", {
            url1,
            data,
          });
          
        })
        .catch(error => {
          this.$dispatch("error", error);
          alert({
            "title": (error.status === 403 )?"Access denied":"Failed",
            "type": "error",
            "text": this.access_denied_msg,
            "currentProgressStep": 0,
          });
        });
      };
      _do();
    },
    "makeRequest": function() {
      console.log(this.staff);
      const $this = this;      
      const confirm = this.confirm;
      
      if (confirm) {
        const alertConfig = {
          "title": "",
          "text": confirm,
          "type": "warning",
          "showCancelButton": true,
          "showLoaderOnConfirm": true,
          "confirmButtonText": this.label || "OK",
          "currentProgressStep": 0,
        };

        // Set confirmation color of button if it has been manually set
        if (this.color) {
          alertConfig.confirmButtonColor = this.color;
        } else {
          // If no color was specified, attempt to determine it from the
          // request element's background color
          const color = window.getComputedStyle(this.$el).backgroundColor;
          if (color) {
            alertConfig.confirmButtonColor = color;
          }
        }
        alert(alertConfig).then($this.callModel, (() => {}));
      } else {
        $this.callModel();
      }
    },
    notificationdishcharg:function(){  
      const date = document.getElementById("leaving_date");
      const address = document.getElementById("leaving_reason");
      let dataValue = "";
      let addressValue = "";

      
      if(date.value !== ""){
        dataValue = date.value; 
      }
      if(address.value !== ""){
        addressValue = address.value; 
      }
      
      
       
      const formData = {"leaving_date" : dataValue, "leaving_reason": addressValue, "id" : this.staff};
      const $this = this;
      $this.$http.post("/staff/pinfoupdate",formData, function(response) { 
                 
        if(response.success==="200"){  
          console.log(response.success);          
          $this.archive();
          setTimeout(function(){ 
            topbar.hide();
            window.location.reload();
          },1000); 
        }else{            
          const showMesuccess = document.getElementById("dishcharge-error");           
          showMesuccess.style.display = "block";
          showMesuccess.innerText = "Leaving Date is mandatory.";
        }
      
      });
     
    }
  }
});
