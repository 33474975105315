/**
 * Bodymap Data Point Component
 *
 */
import Vue from "vue";
import alert from "sweetalert";
import template from "templates/BodymapDataPointNew.html";

export default Vue.extend({
  "props": {
    "id": {
      "type": String,
      "required": false
    },
    "person": {
      "type": String,
      "required": false
    },
    "name": {
      "type": String,
      "required": false
    },
    "class": {
      "type": String,
      "required": false,
      "default": ""
    },
    "x": {
      "type": String,
      "required": false
    },
    "y": {
      "type": String,
      "required": false
    },
    "severity": {
      "type": String,
      "required": false
    },
    "tip": {
      "type": String,
      "required": false
    },
    "bodymap_type": {
      "type": String,
      "required": false
    },
    "any_action": {
      "type": String,
      "required": false
    },
    "safeguarding_concern": {
      "type": String,
      "required": false
    },
    "image": {
      "type": String,
      "required": false
    },
    "dailytask": {
      "type": String,
      "required": false
    },
    "isbodymapmodal": {
      "type":String     
    },
    "point_added_on": {
      "type": String,
      "required": false
    },
    "style": {
      "type": String,
      "required": false
    },
  },
  data: function(){
    return {
      showbodymapEdit: false,
      counter:this.isbodymapmodal
    };
  },
  "methods": {        
    showBodyMapImage: function() {
      alert({
        title: "",
        text: "<p>" + this.tip + "</p><br/><p><img class='bodymap-image' " +
          "src='data:application/image;base64," +
          this.image + "'/><p>",
        html: true
      });
    },
    showImageData: function(event) {  
      document.getElementById("delete_reason_form").value = "";
      document.getElementById("delete_error").innerHTML = "";
      const element = event.target;
      console.log(window.checkBodymap);
      if(window.checkBodymap===undefined){
        window.checkBodymap ="showBDM";
      }  
      if(window.checkBodymap === "addBDM"){
        if (element.getAttribute("data-tip-on") === "false") {

          if (element.getAttribute("data-tip") === "") {
            document.getElementById("bodyImagesrc").style.display = "none"; 
            const bodymapForm = document.getElementsByClassName("bodymapForm")[0];
            document.getElementById("severity").value = "Minor";
            bodymapForm.setAttribute("action","/person/" +
            element.getAttribute("data-person-id") + "/bodymap");
            const bodymapFormTitle = bodymapForm.parentNode
            .getElementsByTagName("h1")[0];
            bodymapFormTitle.innerText = "Add to Body Map";
            document.getElementsByClassName("bodymapFormLink")[0].click();
          }
        }
      }
      else if(window.checkBodymap === "deleteBDM"){
        if(element.getAttribute("id")){
          document.getElementsByName("deletepointId")[0].value = element.getAttribute("id");
          document.getElementsByClassName("bodymapFormLinkdelete")[0].click();
        }
        if(this.severity){
          document.getElementById("delete_serverity").innerHTML = this.severity;  
        }
        if(this.tip){
          document.getElementById("delete_tip").innerHTML = this.tip; 
        }
        if(this.bodymap_type === "Temporary"){
          document.getElementById("delete_bodymap_type_div").style.display = "block";
          document.getElementById("delete_bodymap_type").innerHTML = this.bodymap_type; 
        } else {
          document.getElementById("delete_bodymap_type_div").style.display = "none";  
        }
        if(this.any_action){
          document.getElementById("delete_any_action").innerHTML = this.any_action; 
        }
        if(this.safeguarding_concern){
          document.getElementById("delete_safeguarding_concern").innerHTML = this.safeguarding_concern; 
        }
        if(this.point_added_on){
          document.getElementById("delete_point_added_on").innerHTML = this.point_added_on; 
        }

        if(this.image){
          document.getElementById("show_image_div_id").style.display="block";
          document.getElementById("delete_bd_image")
          .setAttribute("src","data:application/image;base64," + this.image );  
        }else{
          document.getElementById("show_image_div_id").style.display="none";
          document.getElementById("delete_bd_image").setAttribute("src","");
        }
      }
      else if(window.checkBodymap === "showBDM"){ 
        if (element.getAttribute("data-tip-on") === "false") {
          this.showbodymapEdit = true;
        } 
        else {
          this.showbodymapEdit = false;
          element.setAttribute("data-tip-on", "false");
        }
      }
      else if(window.checkBodymap === "showBDMTask" ||
           (element.baseURI.indexOf("bodymap/task") !== -1)){
        if (element.getAttribute("data-tip-on") === "false") {
          if (element.getAttribute("data-tip") === "") {
            document.getElementById("bodyImagesrc").style.display = "none"; 
            const bodymapForm = document.getElementsByClassName("bodymapForm")[0];
            document.getElementById("severity").value = "Minor";
            bodymapForm.setAttribute("action","/person/" +
            element.getAttribute("data-person-id") + "/bodymap");
            const bodymapFormTitle = bodymapForm.parentNode
            .getElementsByTagName("h1")[0];
            bodymapFormTitle.innerText = "Add to Body Map";
            document.getElementsByClassName("bodymapFormLink")[0].click();
          }
          else{
            if(element.getAttribute("id")){
              document.getElementsByName("deletepointId")[0].value = element.getAttribute("id");
              document.getElementsByClassName("bodymapFormLinkdelete")[0].click();
            }
            if(this.severity){
              document.getElementById("delete_serverity").innerHTML = this.severity;  
            }
            if(this.tip){
              document.getElementById("delete_tip").innerHTML = this.tip; 
            }
            if(this.bodymap_type){
              document.getElementById("delete_bodymap_type").innerHTML = this.bodymap_type; 
            }
            if(this.any_action){
              document.getElementById("delete_any_action").innerHTML = this.any_action; 
            }
            if(this.safeguarding_concern){
              document.getElementById("delete_safeguarding_concern").innerHTML = this.safeguarding_concern; 
            }
            if(this.image){
              document.getElementById("show_image_div_id").style.display="block";
              document.getElementById("delete_bd_image").setAttribute("src","data:application/image;base64,"+this.image );  
            }else{
              document.getElementById("show_image_div_id").style.display="none";
              document.getElementById("delete_bd_image").setAttribute("src","");
            }
          }
        }   
      }
    },
    editDataPoint: function(event) {
      const element = event.target;
      this.showbodymapEdit = false;
      const bodymapForm = document.getElementsByClassName("bodymapForm")[0];
      bodymapForm.setAttribute("action","/person/" +
        element.getAttribute("data-person-id") + "/bodymap/" +
        element.getAttribute("data-bodymap-id") + "/edit");
      const bodymapFormTitle = bodymapForm.parentNode
        .getElementsByTagName("h1")[0];
      bodymapFormTitle.innerText = "Edit Body Map";
      const bodymapPointElement = document
        .getElementById(element.getAttribute("data-bodymap-id"));
      document.getElementsByName("bodymap_x")[0]
      .value = bodymapPointElement.getAttribute("data-x");
      document.getElementsByName("bodymap_y")[0]
      .value = bodymapPointElement.getAttribute("data-y");
      document.getElementsByName("severity")[0]
      .value = bodymapPointElement.getAttribute("data-severity");
      const severityItems = document.getElementsByName("severity")[0]
        .parentNode.getElementsByTagName("li");

      bodymapForm.elements.namedItem("image").removeAttribute("required");  
        
      for (let j = 0; j < severityItems.length; j++) {
        severityItems[j].className = "dropdown__list__item";
        if (severityItems[j].innerText.trim() === bodymapPointElement
            .getAttribute("data-severity")) {
          severityItems[j].className += " dropdown__list__item--selected";
        }
      }
      bodymapForm.getElementsByClassName("form__textarea")[0]
        .value = bodymapPointElement.getAttribute("data-tip");

      document.getElementById("bodyImagesrc").style.display = "block"; 
      document.getElementById("bodyImagesrc").src= 
      document.getElementById("bodymapImage" + "" + bodymapPointElement.id).src;
      document.getElementsByClassName("bodymapFormLink")[0].click();
    },
    deleteDataPoint: function(event) {
      this.showbodymapEdit = false;
      const element = event.target;
      alert(
        {
          title: "Are you sure?",
          text: "You will not be able to recover this!",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, delete it!",
          closeOnConfirm: false
        },
        function(isConfirm) {
          if (isConfirm) {
            window.location = "/person/" +
              element.getAttribute("data-person-id") + "/bodymap/" +
              element.getAttribute("data-bodymap-id") + "/delete";
          }
        }
      );
    },
    close: function() {
      this.showbodymapEdit = false;
      this.$els.dataPoint.setAttribute("data-tip-on", "false");
    }
  },
  "template": template,
  "ready": function() {
  }
});
