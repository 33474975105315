/**
 * Datepicker Component
 *
 */

import Vue from "vue";
import flatpickr from "flatpickr";
import template from "templates/Appointmentpicker.html";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "required": false
    },
    "placeholder": {
      "type": String,
      "required": false
    },
    "value": {
      "type": String,
      "required": false
    },
    "format": {
      "type": String,
      "required": false,
      "default": "d-m-Y"
    },
    "maxdate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "mindate": {
      "type": String,
      "required": false,
      "default": ""
    },
    "enabletime": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "enableseconds": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "nocalendar": {
      "type": String,
      "required": false,
      "default": "false"
    },
    "passobject": {
      "type":String,
      "required":false,
      "default":"false",
    },
    "showappointment": {
      "type": Boolean,
      "required": false,
      "twoWay": true,
    } 
  },
  "template": template,
  "ready": function() {
    const $this = this;
    const arr = JSON.parse(atob(this.passobject));
    
    flatpickr(this.$el, {
      dateFormat: this.format,
      enableTime: this.enabletime,
      enableSeconds: this.enableseconds,
      noCalendar: this.nocalendar,
      maxDate: new Date(this.maxdate),
      minDate: new Date(this.mindate),
      inline: true,
      defaultDate: [],
      locale: {
        firstDayOfWeek: 1
      },

      onDayCreate : function(dObj, dStr, instance, dayElem){ 
        const month = instance.currentMonth+ 1;
        const Caldate = dayElem.innerHTML+"-"+month+"-"+instance.currentYear;
        if(arr.appraisal!== undefined){
          arr.appraisal.forEach(function(appoint) {
            if (Caldate === appoint["date"]) {
              if(dayElem.className.search(" appoint")=== -1){
                dayElem.className += " appoint";
              } 
              if(dayElem.hasAttribute("appid")){
                const eleapp = dayElem.getAttribute("appid");
                dayElem.setAttribute("appid", eleapp+" "+appoint["id"]);
              }else{
                dayElem.setAttribute("appid", appoint["id"]);
              }             
            }
          }); 
        }
        if(arr.holidays!== undefined){
          arr.holidays.forEach(function(appoint) {
            if (Caldate === appoint["date"]) {
              if(dayElem.className.search(" appoint")=== -1){
                dayElem.className += " appoint";
              } 
              if(dayElem.hasAttribute("holiid")){
                const ele = dayElem.getAttribute("holiid");
                dayElem.setAttribute("holiid", ele+" "+appoint["id"]);
              }else{
                dayElem.setAttribute("holiid", appoint["id"]);
              }   
            }
          }); 
        } 
        if(arr.supervision!== undefined){
          arr.supervision.forEach(function(appoint) {
            if (Caldate === appoint["date"]) {
              if(dayElem.className.search(" appoint")=== -1){
                dayElem.className += " appoint";
              } 
              if(dayElem.hasAttribute("supid")){
                const ele = dayElem.getAttribute("supid");
                dayElem.setAttribute("supid", ele+" "+appoint["id"]);
              }else{
                dayElem.setAttribute("supid", appoint["id"]);
              }   
            }
          }); 
        } 

        if(arr.n_supervision!== undefined){
          arr.n_supervision.forEach(function(appoint) {
            if (Caldate === appoint["date"]) {
              if(dayElem.className.search(" appoint")=== -1){
                dayElem.className += " appoint";
              } 
              if(dayElem.hasAttribute("supnewid")){
                const ele = dayElem.getAttribute("supnewid");
                dayElem.setAttribute("supnewid", ele+" "+appoint["id"]);
              }else{
                dayElem.setAttribute("supnewid", appoint["id"]);
              }   
            }
          }); 
        }   
      },

      onChange: function(selectedDates, dateStr, event) {
        let appraisalId = ""; 
        let holiId = "";
        let appraisalids = [];
        let holidaysids = [];
        let supId = "";
        let supsids = [];
        let supnewId = "";
        let supsnewids = [];
        document.getElementById("appraisal__id").innerHTML = "";
        document.getElementById("holiday__id").innerHTML = "";
        document.getElementById("supervision__id").innerHTML = "";
        document.getElementById("newsupervision__id").innerHTML = "";
        if (document.getElementsByClassName("appoint").length > 0) {
          if (event.selectedDateElem.hasAttribute("appid")) {
            appraisalId = event.selectedDateElem.getAttribute("appid");
          }

          if (event.selectedDateElem.hasAttribute("holiid")) {
            holiId = event.selectedDateElem.getAttribute("holiid");
          }

          if (event.selectedDateElem.hasAttribute("supid")) {
            supId = event.selectedDateElem.getAttribute("supid");
          }

          if (event.selectedDateElem.hasAttribute("supnewid")) {
            supnewId = event.selectedDateElem.getAttribute("supnewid");
          }

          if (appraisalId !== "") {
            appraisalids = appraisalId.split(" ");
            const formData = {"appraisId" : appraisalids};
            $this.$http.post("/appraisalsperdate", formData, function(response) {
              if (response.length > 0) {
                document.getElementById("appraisal__id").innerHTML = response;
                $this.$compile(document);
              }
            });  
          }

          if (holiId !== "") {
            holidaysids = holiId.split(" ");
            const formData1 = {"holId" : holidaysids};
            $this.$http.post("/holidaysperdate", formData1, function(response) {
              if (response.length > 0) {
                document.getElementById("holiday__id").innerHTML = response;
                $this.$compile(document);
              }
            });
          }

          if (supId !== "") {
            supsids = supId.split(" ");
            const formData2 = {"supId" : supsids};
            $this.$http.post("/supervisionperdate", formData2, function(response) {
              if (response.length > 0) {
                document.getElementById("supervision__id").innerHTML = response;
                $this.$compile(document);
              }
            });
          } 

          if (supnewId !== "") {
            supsnewids = supnewId.split(" ");
            const formData3 = {"supnewId" : supsnewids};
            $this.$http.post("/newsupervisionperdate", formData3, function(response) {
              if (response.length > 0) {
                document.getElementById("newsupervision__id").innerHTML = response;
                $this.$compile(document);
              }
            });
          } 
        } 
      }
    });
  }
});

