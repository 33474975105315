/**
 * Bodymap Component
 *
 */

import Vue from "vue";
import template from "templates/Bodymap.html";

export default Vue.extend({
  "props": {
    action: {
      type: String,
      default: null
    },
  },
  "methods": {
    findPosition: function(el) {
      if(typeof( el.offsetParent ) !== "undefined")
      { 
        let posX = 0, posY = 0;
        for ( posX; el; el = el.offsetParent)
        {
          posX += el.offsetLeft;
          posY += el.offsetTop;
        }
        return [ posX, posY ];
      }
      else
      {
        return [el.x, el.y];
      }
      //return [el.x, el.y];
    },
    getCoordinates: function(event) {   
      let posX = 0;
      let posY = 0;

      const e = event || window.event;
    
      if(e.offsetX || e.offsetY){
        posX = e.offsetX;
        posY = e.offsetY; 
      }
      
      const imageX = posX ;
      const imageY = posY ;
      document.getElementsByClassName("bodymapFormLinkPIW")[0].click();
      document.getElementById("bodymap_x").value = imageX;
      document.getElementById("bodymap_y").value = imageY;
      const tempDataPoint = document
        .getElementsByClassName("datapoint__hidden")[0];
      tempDataPoint.style.left = (imageX - 8) + "px";
      tempDataPoint.style.top = (imageY - 8) + "px";
      tempDataPoint.click();
    },
    drawBodymapPoints: function() {
      const bodymapItems = document.getElementsByName("bodymap-item");
      for (let i = 0; i < bodymapItems.length; i++) {
        bodymapItems[i].style.left = bodymapItems[i]
          .getAttribute("data-x") - 8 + "px";
        bodymapItems[i].style.top = bodymapItems[i]
          .getAttribute("data-y") - 8 + "px";
      }
    },
  },
  "template": template,
  "ready": function() {
    this.drawBodymapPoints();
  }
});