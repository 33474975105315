/**
 * Panel Component
 * Represents a set of collapsable modular content
 */

import Vue from "vue";

import template from "templates/Ajaxpanel.html";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "message": {
      "type": String,
      "required": false
    },
    "open": {
      "type": Boolean,
      "required": false,
      default: true
    },
    "url": {
      "type": String,
      "required": true
    },
    "onload": {
      "type": String,
      "required": true
    }
  },
  /*"data": function() {
    return {
      "open": true
    };
  },*/
  "template": template,
  "methods": {
    "toggle": function() {
      this.open = !this.open;

      if(this.open)
      {
        const parent = this.$els;
        const html = "<div class='col-md-12'><div class='text-center'>"+
          "<p><img src='/images/dual_spinner_dark.svg' alt='Loading...'></p>"+
          "<p> Loading content, Please Wait...<i class='fa fa-smile-o'></i></p>"+
          "</div></div>";
        //parent.loadinggif.style.visibility = "hidden"; 
        parent.ajax_content.innerHTML = html;
        const $this = this;
        $this.$parent.$compile(parent.ajax_content);
        $this.$http.get(this.url)
          .then(response => { 
            if(response) {
              
              parent.ajax_content.innerHTML = response.data;
              $this.$parent.$compile(parent.ajax_content);
            }
          }, response => {
            if(response)
              parent.ajax_content.innerHTML = "";
          });
      }
    },
    "reload": function() {
      //if(true)
      {
        const parent = this.$els;
        const html = "<div class='col-md-12'><div class='text-center'>"+
          "<p><img src='/images/dual_spinner_dark.svg' alt='Loading...'></p>"+
          "<p> Loading content, Please Wait...<i class='fa fa-smile-o'></i></p>"+
          "</div></div>";
        //parent.loadinggif.style.visibility = "hidden"; 
        parent.ajax_content.innerHTML = html;
        const $this = this;
        $this.$parent.$compile(parent.ajax_content);
        $this.$http.get(this.url)
          .then(response => { 
            if(response) {
              
              parent.ajax_content.innerHTML = response.data;
              $this.$parent.$compile(parent.ajax_content);
            }
          }, response => {
            if(response)
              parent.ajax_content.innerHTML = "";
          });
      }
    }
  },
  "ready": function() {

    if(this.onload === "true"){
      const parent = this.$els;
      const html = "<div class='col-md-12'><div class='text-center'>"+
        "<p><img src='/images/dual_spinner_dark.svg' alt='Loading...'></p>"+
        "<p> Loading content, Please Wait...<i class='fa fa-smile-o'></i></p>"+
        "</div></div>";
      //parent.loadinggif.style.visibility = "hidden"; 
      parent.ajax_content.innerHTML = html;
      //console.log(parent.ajax_content.innerHTML);
      const $this = this;
      $this.$parent.$compile(parent.ajax_content);
      $this.$http.get(this.url)
        .then(response => { 
          if(response) {
            
            parent.ajax_content.innerHTML = response.data;
            $this.$parent.$compile(parent.ajax_content);
          }
        }, response => {
          if(response)
            parent.ajax_content.innerHTML = "";
        });
    }
  }
});
