/**
 * Import and register all global components
 */

import Vue from "vue";

import RequestButton from "./RequestButton";
Vue.component("request", RequestButton);

import RequestButtonReload from "./RequestButtonReload";
Vue.component("requestreload", RequestButtonReload);

import RotaRequestButton from "./RotaRequestButton";
Vue.component("rota-request", RotaRequestButton);

import RequestForm from "./RequestForm";
Vue.component("request-form", RequestForm);

import Dropdown from "./Dropdown";
Vue.component("dropdown", Dropdown);

import Panel from "./Panel";
Vue.component("panel", Panel);

import Modal from "./Modal";
Vue.component("modal", Modal);

import Checkin from "./Checkin";
Vue.component("checkin", Checkin);

import Datepicker from "./Datepicker";
Vue.component("datepicker", Datepicker);

import Bodymap from "./Bodymap";
Vue.component("bodymap", Bodymap);

import BodymapDataPoint from "./BodymapDataPoint";
Vue.component("bodymapdatapoint", BodymapDataPoint);

import TimeDisplay from "./TimeDisplay";
Vue.component("time-display", TimeDisplay);

import RotaList from "./RotaList";
Vue.component("rota-list", RotaList);

import RotaTimeline from "./RotaTimeline";
Vue.component("rota-timeline", RotaTimeline);

import ShiftDetail from "./ShiftDetail";
Vue.component("shift-detail", ShiftDetail);

import Rota from "./Rota";
Vue.component("rota-view", Rota);

import RotaGenerator from "./RotaGenerator";
Vue.component("rota-generator", RotaGenerator);

import Chart from "./Chart";
Vue.component("chart", Chart);

import ImagePreview from "./ImagePreview";
Vue.component("imgepreview", ImagePreview);

import Durationpicker from "./Durationpicker";
Vue.component("Durationpicker", Durationpicker);

import {NewModal} from "./Modal";
Vue.component("n-modal", NewModal);

import AjaxPanel from "./Ajaxpanel";
Vue.component("ajaxpanel", AjaxPanel);

import VueTextarea from "./VueTextarea";
Vue.component("vuetextarea", VueTextarea);

import StaffRota from "./StaffRota";
Vue.component("staff-rota", StaffRota);

import BodymapDataPointNew from "./BodymapDataPointNew";
Vue.component("bodymapdatapointnew", BodymapDataPointNew);

import BodyMapNew from "./BodyMapNew";
Vue.component("bodymapnew", BodyMapNew);

import Datepickerfilter from "./Datepickerfilter";
Vue.component("datefilter", Datepickerfilter);

import Geolocation from "./Geolocation";
Vue.component("geolocation", Geolocation);

import Signature from "./Signature";
Vue.component("signature-pad", Signature);

import RequestButtonNew from "./RequestButtonNew";
Vue.component("requestnew", RequestButtonNew);

import Appointmentpicker from "./Appointmentpicker";
Vue.component("appointmentpicker", Appointmentpicker);