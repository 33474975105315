import Vue from "vue";
import template from "templates/ImagePreview.html";

export default Vue.extend({
  template: template,
  props:  {
    "name": {
      "type": String
    },
    "defaultimage": {
      "type": String
    },
    "checkboxid": {
      "type": String
    },
    "required": {
      "type": String,
      "default": "false"
    },
    "checkboxval": {
      "type": String,
      "default": "0"
    }
  },
  data: function() {
    return {
      image: this.defaultimage,
      fieldname: this.name,
      active: "",
      checked: "0"

    };
  },
  ready: function() {
    this.active = this.checkboxid;
    this.checked = this.checkboxval;
  },
  methods: {
    onFileChange(e) {
      if(!e.target.hasAttribute("required") && document.getElementById(this.active)){

        document.getElementById(this.active).removeAttribute("required");
      }
      const files = e.target.files || e.dataTransfer.files;
      if (!files.length) {
        return;
      }
      document.getElementById("file"+e.target.name).innerHTML= "";
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      if(!allowedExtensions.exec(e.target.value)){
        e.target.value = "";
        document.getElementById("file"+e.target.name)
        .innerHTML= "Please upload an image.";
        return false;
      }

      if(!e.target.hasAttribute("required")
        && document.getElementById(this.active)
        && e.target.value !== ""){

        document.getElementById(this.active).setAttribute("required",true);
      }
      //1000000 = 1 MB
      if((files[0].size/1000000) > 10 ){
        e.target.value ="";
        document.getElementById("file"+e.target.name)
        .innerHTML= "Please upload an image not more than 10MB";
        return;
      }

      this.createImage(files[0]);
      this.disabled = false;
    },
    createImage(file) {
      const reader = new FileReader();
      const vm = this;
      vm.image = new Image();
      reader.onload = (e) => {
        vm.image = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
});
