/**
 * Signature Component
 *
 */

import Vue from "vue";
import SignaturePad from "signature_pad";
import alert from "sweetalert2";
import template from "templates/Signature.html";

export default Vue.extend({
  "props": {
    "name": {
      "type": String,
      "required": true
    },
    "class": {
      "type": String,
      "default": "form-group"
    },
    "required": {
      "type": String,
      "default": "no",
      "required": false
    }
  },
  "template": template,
  data() {
    return {
      wrapper: null,
      clearButton: null,
      canvas: null,
      signaturePad: null,
      ratio: null,
      timer: null
    };
  },
  methods: {
    resizeCanvas: function resizeCanvas() {
      const _this = this;
      //let minHeight = 200;
      //if(_this.$els.myCanvas.offsetHeight > 200) {
        //minHeight = _this.$els.myCanvas.offsetHeight;
      //}
      _this.ratio =  Math.max(window.devicePixelRatio || 1, 1);          
      _this.canvas.width = _this.$els.myCanvas.offsetWidth * this.ratio;
      //_this.canvas.height = minHeight * this.ratio;
      _this.canvas.height = 250;
      _this.canvas.getContext("2d").scale(_this.ratio, _this.ratio);
      if(_this.$els.myCanvas.offsetWidth > 0) {
        document.getElementById(_this.name + "_blank").value = _this.signaturePad
        .toDataURL().split(",")[1];
        clearInterval(_this.timer);
      }
    }
  },
  ready: function() {
    const _this = this;
    _this.wrapper = document.getElementById(_this.name + "-pad");
    _this.clearButton = _this.wrapper.querySelector("[data-action=clear]");
    _this.canvas = _this.wrapper.querySelector("canvas");
    /*const windowWidth = window.innerWidth;
    _this.ratio =  Math.max(window.devicePixelRatio || 1, 1);
    let canvasWidth = _this.width;
    if (_this.width.indexOf("%") !== -1) {
      _this.width = _this.width.replace("%", "");
      canvasWidth = Math.round(windowWidth * (_this.width/100));
    }
    _this.canvas.width = canvasWidth * _this.ratio;
    _this.canvas.height = _this.height * _this.ratio;
    _this.canvas.getContext("2d").scale(_this.ratio, _this.ratio);*/
    window.onresize = _this.resizeCanvas;
    //window.onload = _this.resizeCanvas;
    
    _this.timer = setInterval(() => {
      _this.resizeCanvas();
    }, 1000);
    _this.signaturePad = new SignaturePad(_this.canvas);
    _this.clearButton.addEventListener("click", function () {
      console.log("reset");
      _this.signaturePad.clear();
      document.getElementById(_this.name).value = "";
    });
    const form = this.clearButton.form;
    window.onload = function () {
      form.onsubmit = function onSubmit() {
        console.log("on submit");
        //console.log("signature pad 1",_this.signaturePad.isEmpty());
        if(_this.required === "true" && _this.signaturePad.isEmpty())
        {
          return false;
        }
        else
        {
          document.getElementById(_this.name).value = _this.signaturePad.toDataURL().split(",")[1];
        }
      };
    };
    form.addEventListener("submit", function(evt){
      //console.log("signature pad 2",_this.signaturePad.isEmpty());
      if(_this.required === "true" && _this.signaturePad.isEmpty())
      { evt.preventDefault();
        alert({
          "title": "",
          "text": "Your Signature is Required!",
          "type": "warning",
          "showCancelButton": false,
          "showLoaderOnConfirm": true,
          "confirmButtonText": "OK",
          "currentProgressStep": 0,
        });
        return false;
      }
      else{
        document.getElementById(_this.name).value = _this.signaturePad.toDataURL().split(",")[1];
      } 
    });
  }
});
