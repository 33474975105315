/**
 * RequestButton Component
 * Represents a clickable link/button that will perform a request
 * via AJAX with an optional confirmation dialogue
 */

import Vue from "vue";
import alert from "sweetalert2";
import topbar from "topbar";

import template from "templates/RequestButton.html";

export default Vue.extend({
  "props": {
    "href": {
      "type": String,
      "required": true
    },
    "method": {
      "type": String,
    },
    "deletes": {
      "type": Boolean
    },
    "confirm": {
      "type": String,
      "validator": function(value) {
        return !!value.length;
      }
    },
    "color": {
      "type": String,
      "validator": function(value) {
        return /#[0-9A-F]{3}([0-9A-F]{3})?/.test(value);
      }
    }
  },
  "template": template,
  "methods": {
    "makeRequest": function(url) {
      // Defer the actual request making to a method so we can
      // wait for confirmation
      const _do = () => {
        topbar.show();
        this.$http({
          "url": url,
          "method": this.method ? this.method : "GET"
        })
        .then(({ data }) => {
          const shouldDelete = this.deletes;
          if (shouldDelete) {
            let parent = this.$el.parentNode;
            // Find deletable parent node
            while (parent && !parent.classList.contains("deletable")) {
              parent = parent.parentNode;
            }

            if (parent && parent.parentNode) {
              parent.parentNode.removeChild(parent);
            }
          }
          this.$dispatch("success", {
            url,
            data,
          });
          alert({
            "title": "Success",
            "type": "success",
            "currentProgressStep": 0,
          }).then(() => {
            window.location.reload();
          });
        })
        .catch(error => {
          this.$dispatch("error", error);
          alert({
            "title": "Failed",
            "type": "error",
            "currentProgressStep": 0,
          }).then(() => {
            topbar.hide();
          });
        })
        .then(function() {
          // Always hide the top bar progress
          topbar.hide();
        });
      };
      const confirm = this.confirm;
      console.log(this.label);
      if (confirm) {
        const alertConfig = {
          "title": "",
          "text": confirm,
          "type": "warning",
          "showCancelButton": true,
          "showLoaderOnConfirm": true,
          "confirmButtonText": this.label || "OK",
          "currentProgressStep": 0,
        };

        // Set confirmation color of button if it has been manually set
        if (this.color) {
          alertConfig.confirmButtonColor = this.color;
        } else {
          // If no color was specified, attempt to determine it from the
          // request element's background color
          const color = window.getComputedStyle(this.$el).backgroundColor;
          if (color) {
            alertConfig.confirmButtonColor = color;
          }
        }

        alert(alertConfig).then(_do, (() => {}));
      } else {
        // Run request immediately if no confirmation is required
        _do();
      }
    }
  }
});
